.modal {
    .modal-header {
        justify-content: flex-end;
        padding-bottom: 0;
        border-bottom: none;

        .icon-circle-x {
            cursor: pointer;
        }
    }

    .modal-body {
        .subtitle {
            text-align: center;
        }

        .input {
            width: 100%;

            input {
                width: 100%;
            }
        }

        a {
            color: $primary;
            font-family: $font-base-bold;
        }

        .links {
            text-align: center;

            p {
                margin-bottom: 0;
            }
        }

        .cta {
            margin: 30px 0 20px;
            text-align: center;
        }
    }
}

.modal-backdrop + .modal-backdrop {
    opacity: 0;
}
